.projBox {
    background-color: rgb(0, 119, 255);
    border-radius: 20px;
    /* display: inline-block;
    height: 0;
    padding-bottom: 100%; */
    width: 100%;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    /* position: relative; */
    margin-top: 1.5rem;
}

.purple {
    background-color: rgb(185, 66, 179);
}

.projTitle {
    color: white;
    padding-top: 8%;
    padding-left: 8%;
}

.projText {
    color: white;
    padding-left: 8%;
    opacity: .6;
}

.projImg {
    height: 335px;
    margin-left: 8%;
    border-radius: 10px;
}

.projArrow {
    position: absolute;
    top: 8%;
    right: 5%;
}

.csLogo {
    position: absolute;
    top: 8%;
    right: 5%;
    height: 45px;
}

.projCont {
    transition: transform .2s;
    position: relative;
}

.projCont:hover {
    transform: scale(1.03);
}

@media (min-width: 992px) and (max-width: 1199px) {
    .projImg {
        min-height: 350px;
        min-width: 440px;
    }
}

@media (max-width: 767px) {
    .projBox {
        max-height: 400px;
    }
    .projImg {
        min-height: calc(100% - 50px);
        min-width: calc(100% - 10px);
        max-width: 100%;
        object-fit: cover;
        object-position: left top;
    }
}