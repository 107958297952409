h1 {
    font-family: 'Lato', sans-serif;
    font-size: 50px;
    /* padding-left: 75px; */
}

.glassBox {
    /* background-image: url('../assets/images/aboutBG.jpeg'); */
    background: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    background-size: cover;
    /* backdrop-filter: blur(6.2px); */
    height: 100%;
    width: 100%;
}

.aboutBox {
    height: 400px;
    /* background-color: rgb(244, 244, 244, .5); */
    border-radius: 20px;
    background-image: url('../assets/images/aboutBG.jpeg');
    background-size: cover;
    /* backdrop-filter: blur(6.2px); */
}

.innerAboutBox {
    padding-left: 75px;
}

.typedText {
    color: rgb(255, 220, 40);
    /* text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.02); */
    /* text-shadow: 1px 0 0 rgb(200, 160, 19), 0 -1px 0 rgb(200, 160, 19), 0 1px 0 rgb(200, 160, 19), -1px 0 0 rgb(200, 160, 19); */
}

button {
    border-radius: 50px;
    border: none;
}

.btnRow {
    padding-top: 100px;
    /* display: flex; */
    /* flex: wrap; */
}

.conBtn {
    width: 200px;
    height: 60px;
    background-color: black;
    color: white;
    font-size: 20px;
    font-weight: 600;
}

.conBtn:hover {
    background-color: rgb(37, 37, 37);
}

.conSmallBtn {
    display: none;
}

.socBtn {
    margin-left: 20px;
    height: 60px;
    width: 60px;
    /* color: white; */
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.049);
    transition: background-color .35s ease-out;
}

.socLogo {
    color: black;
    text-align: center;
    width: 100%;
}

.socBtn:hover .socLogo {
    color: white;
}

.liBtn:hover {
    background-color: #0077b5;
}

.cwBtn:hover {
    background-color: rgb(177,54,30);
}

.ghBtn:hover {
    background-color: black;
}

.phBtn:hover {
    background-color: green;
}

@media (max-width: 1199px) {
    h1 {
        font-size: 45px;
    }

    .innerAboutBox {
        padding-left: 45px;
    }
}

@media (max-width: 991px) {
    h1 {
        font-size: 30px;
    }
    
    .h1-pad {
        padding-top: 35px !important;
    }

    .btnRow {
        padding-top: 45px;
    }

    .innerAboutBox {
        padding-left: 35px;
    }

    .aboutBox {
        height: 300px;
    }

    .conBtn {
        /* display: none; */
        margin-right: 250px;
        width: 145px;
        height: 48px;
        font-size: 17px;
        font-weight: 600;
    }

    .socBtn {
        margin-left: 20px;
        margin-top: 12px;
        height: 50px;
        width: 50px;
    }

    .ghBtn {
        margin-left: 0;
    }
}

@media (max-width: 767px) {
    .aboutBox {
        margin-top: 1.5rem;
    }

    .conBtn {
        margin-right: calc(100% - 190px);
    }
}

@media (max-width: 480px) {
    h1 {
        font-size: 27px;
        /* padding-left: 75px; */
    }
}