.headBox {
    height: 400px;
    background-color: rgb(0, 166, 255);
    border-radius: 20px;
    overflow-y: hidden;
}

.headPic {
    width: 100%;
    padding-left: 20px;
    padding-top: 30px;
}

@media (max-width: 991px) {
    .headBox {
        height: 300px;
    }
}

@media (max-width: 767px) {
    .headPic {
        padding-top: 20px;
        max-width: 340px;
        /* width: 400px; */
        /* height: auto; */
        margin: auto;
    }
    .headBox {
        /* display: flex; */
        /* justify-content: center; */
        text-align: center;
        height: 250px;
    }
  }